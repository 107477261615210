import * as React from "react";

import Layout from "../components/layout";

const IndexPage = () => {
  const diseases = [
    {
      title: "Στένωση ουρήθρας",
      text: "εσωτερική ουρηθροτομή",
    },

    {
      title: "Βραχύς χαλινός",
      text: "πλαστική χαλινού",
    },
    {
      title: "Φίμωση",
      text: "περιτομή",
    },
    {
      title: "Διερεύνηση αιματουρίας",
      text: "",
    },
    {
      title: "Διερεύνηση λιθίασης ουροποιητικού",
      text: "ενδοσκοπική λιθοτριψία",
    },

    {
      title: "Καρκίνος κύστης",
      text: "ενδοσκοπική αφαίρεση, κυστεκτομή",
    },

    {
      title: "Καρκίνος νεφρού",
      text: "ριζική νεφρεκτομή",
    },

    {
      title: "Καρκίνος όρχεως",
      text: "ορχεκτομή",
    },
    {
      title: "Καρκίνος πέους",
      text: "μερική, ολική πεεκτομή",
    },
    {
      title: "Ανδρική υπογονιμότητα",
      text: "",
    },

    {
      title: "Στυτική δυσλειτουργία",
      text: "",
    },
    {
      title: "Ανδρική υπογονιμότητα",
      text: "",
    },
    {
      title: "Ουροροομετρία σε άνδρες, γυναίκες, παιδιά",
      text: "",
    },
    {
      title: "Υπερηχογράφημα νεφρών, ουροδόχου κύστεως, προστάτη αδένα",
      text: "",
    },
    {
      title: "Διορθικό υπερηχογράφημα και βιοψίες προστάτη",
      text: "",
    },
    {
      title: "Triplex όρχεων, οσχέου, πέους, νεφρών",
      text: "",
    },
    {
      title: "Ακράτεια ούρων σε άνδρες, γυναίκες, παιδιά",
      text: "",
    },
    {
      title: "Υπέρηχος ουροποιητικού(νεφρών–προστάτη-κύστης)",
      text: "",
    },
    {
      title: "Επίσκεψη κατ’ οίκον",
      text: "",
    },
    {
      title: "Θηλώματα κύστης",
      text: "",
    },
    {
      title: "Λιθίαση (πέτρες στα νεφρά, στην κύστη, στον ουρητήρα)",
      text: "",
    },
  ];

  return (
    <Layout>
      <div className="container">
        <h1 className="pageTitle"> Επεμβάσεις</h1>
        <div className="pageContent">
          <div>
            <div className="col3-grid">
              {diseases.map((item, index) => {
                return (
                  <div className="col3-grid-item" key={index}>
                    <h3 className="col3-grid-title">{item.title}</h3>
                    <div>{item.text}</div>
                  </div>
                );
              })}
            </div>
            <div>
              Τα χειρουργεία γίνονται ενδοσκοπικά, λαπαροσκοπικά, ανοιχτά και
              πάντα επί ενδείξεων.
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
